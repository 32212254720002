import React from "react";
import { useContext } from "react";
import { Badge } from "react-bootstrap";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";

function TypePill({ type }) {
  const { language } = useContext(LanguageContext);

  switch (type) {
    case "spo2":
      return <Badge className="btn-pink">SpO2</Badge>;
    case "hemoglobine":
      return (
        <Badge className="btn-primary">{d["hemoglobine"][language]}</Badge>
      );
    case "creatinine":
      return (
        <Badge className="btn-secondary">{d["creatinine"][language]}</Badge>
      );
    case "weight":
      return <Badge className="btn-success">{d["weight"][language]}</Badge>;
    case "glycemia":
      return <Badge className="btn-info">{d["glycemia"][language]}</Badge>;
    case "pressure":
      return <Badge className="btn-warning">{d["pressure"][language]}</Badge>;
    case "thyroid":
      return <Badge className="btn-danger">{d["thyroid"][language]}</Badge>;
    case "temperature":
      return <Badge className="btn-light">{d["temperature"][language]}</Badge>;
    case "hba1c":
      return <Badge className="btn-dark">Hba1c</Badge>;
    case "hbA1c":
      return <Badge className="btn-dark">HbA1c</Badge>;
    case "t3":
      return <Badge className="btn-danger">T3</Badge>;
    case "thyroid_t3":
      return <Badge className="btn-danger">T3</Badge>;
    case "t4":
      return <Badge className="btn-danger">T4</Badge>;
    case "thyroid_t4":
      return <Badge className="btn-danger">T4</Badge>;
    default:
      return <Badge>{type}</Badge>;
  }
}

export default TypePill;
