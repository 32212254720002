import React, { useState, useEffect, useContext, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import { Spinner, Table, Modal } from "react-bootstrap";
import patient from "../../images/avatar/patient.png";
import woman from "../../images/avatar/woman.png";
import moment from "moment";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { toast } from "react-toastify";
import StatusBadge from "../components/custom/StatusBadge";

function Appointments() {
  const { language } = useContext(LanguageContext);
  const token = Cookies.get("jwt");
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("all");
  const [error, setError] = useState("");

  // modal elements
  const [loadingButton, setLoadingButton] = useState(false);
  const [patients, setPatients] = useState("");
  const [selectedPatient, setSelectedPatient] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [note, setNote] = useState("");
  const [showModal, setShowModal] = useState(false);
  //min date for the modal input field
  const [minDate, setMinDate] = useState(null);
  useEffect(() => {
    const min_date = new Date();
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
  }, []);

  //appointments from database
  const [fetchData, setFetchData] = useState("");
  const { data } = fetchData;

  // api call to set the appointment.
  const addAppointment = async () => {
    setLoadingButton(true);
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        patient_id: selectedPatient,
        appointementDate: date + " " + time,
        appointementNote: note,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/doctor/appointement/add",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        setShowModal(false);
        toast.success(data.message);
      } else if (response.status === 409) {
        setError(data.message);
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingButton(false);
    }
  };

  useEffect(() => {
    let ignore = false;
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const getData = async (url) => {
      try {
        let response = await fetch(url, request, { mode: "cors" });
        if (response.status === 200) {
          let data = await response.json();
          setFetchData(data);
        } else if (response.status === 401) {
          console.log("unauthorized");
        }
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (!ignore) {
      getData("https://pharmacy.shls.care/api/doctor/appointements");
    }
    return () => {
      ignore = true;
      setLoading(false);
    };
  }, [token]);
  useEffect(() => {
    let ignore = false;
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    const getData = async (url) => {
      try {
        let response = await fetch(url, request, { mode: "cors" });
        if (response.status === 200) {
          let data = await response.json();
          setPatients(data);
        } else if (response.status === 401) {
          console.log("unauthorized");
        }
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (!ignore) {
      getData("https://pharmacy.shls.care/api/doctor/patients");
    }
    return () => {
      ignore = true;
      setLoading(false);
    };
  }, [token]);

  const filter = () => {
    if (data !== undefined) {
      let newData = [...data];
      newData.sort((a, b) => {
        return (
          Date.parse(a.appointement_date) - Date.parse(b.appointement_date)
        );
      });
      let notExpired = newData.filter((obj) => {
        return Date.parse(obj.appointement_date) > Date.now();
      });
      let expired = newData.filter((obj) => {
        return Date.parse(obj.appointement_date) < Date.now();
      });
      expired.reverse();
      let statusFiltered = notExpired.filter(
        (obj) => obj.appointement_status === keyword
      );
      if (keyword === "all") {
        return notExpired;
      } else if (keyword === "expired") {
        return expired;
      } else {
        return statusFiltered;
      }
    }
  };
  //memo
  const memoAppointments = useMemo(filter, [keyword, data]);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <>
      <div className="d-md-flex d-block mb-3 pb-3 border-bottom">
        <div className="card-action card-tabs mb-md-0 mb-3  mr-auto">
          <ul as="ul" className="nav nav-tabs tabs-lg">
            <li className="nav-item">
              <a
                onClick={() => {
                  setKeyword("all");
                }}
                href="#all"
                className={keyword === "all" ? "nav-link active" : "nav-link"}
              >
                {d["all"][language]}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  setKeyword("accepted");
                }}
                href="#accepted"
                className={
                  keyword === "accepted" ? "nav-link active" : "nav-link"
                }
              >
                {d["accepted"][language]}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  setKeyword("sending");
                }}
                href="#pending"
                className={
                  keyword === "sending" ? "nav-link active" : "nav-link"
                }
              >
                {d["pending"][language]}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  setKeyword("rejected");
                }}
                href="#rejected"
                className={
                  keyword === "rejected" ? "nav-link active" : "nav-link"
                }
              >
                {d["rejected"][language]}
              </a>
            </li>
            <li className="nav-item">
              <a
                onClick={() => {
                  setKeyword("expired");
                }}
                href="#expired"
                className={
                  keyword === "expired" ? "nav-link active" : "nav-link"
                }
              >
                {d["expired"][language]}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <Link
            to={"#"}
            onClick={() => setShowModal(true)}
            data-toggle="modal"
            data-target="#addOrderModal"
            className="btn btn-primary rounded"
          >
            <i className="fa fa-calendar mr-2 scale5" aria-hidden="true"></i>+{" "}
            {d["new appointment"][language]}
          </Link>

          {/* modal */}
          <Modal
            centered
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
            className="modal fade"
            id="addOrderModal"
          >
            <div role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {d["new appointment"][language]}
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                    }}
                    className="close"
                    data-dismiss="modal"
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addAppointment();
                    }}
                  >
                    <div className="form-group">
                      <label className="text-black font-w500">Patient</label>
                      <select
                        style={selectedPatient === "" ? {} : { color: "black" }}
                        name="patients"
                        className="form-control"
                        onChange={(e) => {
                          setSelectedPatient(e.target.value);
                        }}
                        required="required"
                      >
                        <option value="">
                          {d["choose a patient"][language]}
                        </option>
                        {patients.accepted?.map((p, index) => {
                          return (
                            <option key={p.patient_id} value={p.patient_id}>
                              {p.patient_firstname} {p.patient_lastname}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["appointment date"][language]}
                      </label>
                      <input
                        style={date === "" ? {} : { color: "black" }}
                        required
                        min={minDate}
                        id="dateInput"
                        type="date"
                        className="form-control"
                        value={date}
                        onChange={(e) => {
                          setDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["appointment time"][language]}
                      </label>
                      <input
                        required
                        style={time === "" ? {} : { color: "black" }}
                        type="time"
                        className="form-control"
                        placeholder={d["appointment time"][language]}
                        value={time}
                        onChange={(e) => {
                          setTime(e.target.value);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-black font-w500">
                        {d["note"][language]}
                      </label>
                      <textarea
                        style={note === "" ? {} : { color: "black" }}
                        placeholder={d["note"][language]}
                        className="form-control"
                        rows="5"
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      ></textarea>
                    </div>

                    {error && (
                      <div style={{ color: "red" }} className="text-center">
                        {d[`${error}`]?.[language]}
                      </div>
                    )}
                    <div className="form-group text-center pt-3">
                      {loadingButton ? (
                        <button type="button" className="btn btn-primary">
                          <Spinner animation="border" />
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          {d["add appointment"][language]}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Table className="project-bx card-table rounded table-hover fs-14 table bg-white">
            <thead>
              <tr>
                <th>{d["note"][language]}</th>
                <th>{d["appointment date"][language]}</th>
                <th>{d["patient"][language]}</th>
                <th>{d["status"][language]}</th>
              </tr>
            </thead>
            <tbody>
              {memoAppointments?.map((item) => (
                <tr
                  key={item.appointement_id}
                  onClick={() => history.push(`/patient:${item.patient_id}`)}
                >
                  <td>
                    <div>
                      <h4 className="title font-w600 mb-2">
                        <span to={"/post-details"} className="text-black">
                          {item.appointement_note || d["no note"][language]}
                        </span>
                      </h4>
                      <div className="text-dark text-nowrap">
                        <i
                          className="fa fa-calendar-o mr-3"
                          aria-hidden="true"
                        ></i>
                        {d["created at"][language]}{" "}
                        {moment(item.created_at).format("YYYY-MM-DD")}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="bolt-icon mr-sm-3 mr-2">
                        <i className="fa fa-bolt" aria-hidden="true"></i>
                      </span>
                      <div>
                        <p className="mb-sm-1 mb-0 text-dark">
                          {d["appointment date"][language]}
                        </p>
                        <span className="text-black font-w600 text-nowrap">
                          {moment(item.appointement_date).format(`YYYY-MM-DD`)}{" "}
                          {d["at"][language]}{" "}
                          {moment(item.appointement_date).format(`HH:mm`)}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          item.patient_sexe === "male" || "Male"
                            ? patient
                            : woman
                        }
                        alt=""
                        className="rounded-circle mr-sm-3 mr-2 img-2"
                      />
                      <div>
                        <p className="mb-sm-1 mb-0 text-dark text-nowrap">
                          {d["patient"][language]}
                        </p>
                        <span className="text-black font-w600">
                          {item.patient_firstname + " " + item.patient_lastname}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    {keyword === "expired" ? (
                      <span className="btn btn-dark rounded">
                        {d["expired"][language]}
                      </span>
                    ) : (
                      <StatusBadge status={item.appointement_status} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Appointments;
