import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Alert from "sweetalert2";
import Cookies from "js-cookie";
import frLocale from "@fullcalendar/core/locales/fr";

const token = Cookies.get("jwt");

class EventCalendar extends Component {
  lang = localStorage.getItem("userLanguage") || "fr";

  state = {
    time: 8,
    calendarWeekends: true,
    patients: [],
    calendarEvents: [
      {
        title: "Atlanta Monster",
        start: new Date("2019-04-04 00:00"),
        id: "99999998",
      },
      {
        title: "My Favorite Murder",
        start: new Date("2019-04-05 00:00"),
        id: "99999999",
      },
    ],
    events: [
      { title: "Event 1", id: "1" },
      { title: "Event 2", id: "2" },
      { title: "Event 3", id: "3" },
      { title: "Event 4", id: "4" },
      { title: "Event 5", id: "5" },
    ],
  };

  /**
   * adding dragable properties to external events through javascript
   */
  componentDidMount() {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let id = eventEl.getAttribute("data");
        return {
          title: title,
          id: id,
        };
      },
    });
    const fetchData = async () => {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };

      try {
        let response = await fetch(
          "https://pharmacy.shls.care/api/doctor/follow/patients?status=accepted",
          request,
          { mode: "cors" }
        );
        const data = await response.json();
        if (response.status === 200) {
          this.setState({ patients: data.data.patients });
        } else {
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }

  eventDrop = (eventDrop) => {
    console.log(eventDrop.date);
    Alert.fire({
      html: `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr >
      Select time
      </tr>
      <tr >
      <input type="time" value=${this.time}/></tr>
      </tbody>
      </table>
      </div>`,

      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Set Time",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.isConfirmed) {
        eventDrop.date.setHours(8);
        console.log(eventDrop.date.setHours(8));

        // eventDrop.event.remove(); // It will remove event from the calendar
        // Alert.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };
  /**
   * when we click on event we are displaying event details
   */
  eventClick = (eventClick) => {
    Alert.fire({
      title: eventClick.event.title,
      html:
        `<div className="table-responsive">
      <table className="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
        eventClick.event.title +
        `</strong></td>
      </tr>
      <tr >
      <td>Start Time</td>
      <td><strong>
      ` +
        eventClick.event.start +
        `
      </strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,

      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Remove Event",
      cancelButtonText: "Close",
    }).then((result) => {
      if (result.value) {
        eventClick.event.remove(); // It will remove event from the calendar
        Alert.fire("Deleted!", "Your Event has been deleted.", "success");
      }
    });
  };

  render() {
    return (
      <div className="animated fadeIn demo-app">
        <Row>
          <Col lg={3}>
            <div className="card">
              <div className="card-header border-0 pb-0">
                <h4 className="card-title fs-20 mb-0">Events</h4>
              </div>
              <div className="card-body" id="external-events">
                {this.state.patients.map((patient, index) => (
                  <div
                    className="fc-event btn-block btn btn-primary"
                    title={
                      patient.patient_firstname + " " + patient.patient_lastname
                    }
                    data={patient.patient_id}
                    key={index}
                  >
                    {patient.patient_firstname} {patient.patient_lastname}
                  </div>
                ))}
              </div>
            </div>
          </Col>
          <Col lg={9}>
            <div className="card">
              <div className="card-body">
                <div className="demo-app-calendar" id="mycalendartest">
                  <FullCalendar
                    // defaultView="dayGridMonth"
                    // header={{
                    //   left: "prev,next today",
                    //   center: "title",
                    //   right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    // }}
                    rerenderDelay={10}
                    eventDurationEditable={false}
                    locales={[frLocale]}
                    locale={this.lang}
                    editable={true}
                    droppable={true}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    ref={this.calendarComponentRef}
                    weekends={this.state.calendarWeekends}
                    events={this.state.calendarEvents}
                    // eventDrop={this.eventDrop}
                    drop={this.eventDrop}
                    eventReceive={this.eventReceive}
                    eventClick={this.eventClick}
                    // selectable={true}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EventCalendar;
