import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";
import { useContext } from "react";
import Cookies from "js-cookie";
// import jwt from "jsonwebtoken";
import { toast } from "react-toastify";

function PharmacistProfile() {
  const { language } = useContext(LanguageContext);
  const [activeToggle, setActiveToggle] = useState("settings");
  const [data, setData] = useState({
    pharmacy_firstname: "",
    pharmacy_lastname: "",
    pharmacy_email: "",
    pharmacy_address: "",
    pharmacy_phonenumber: "",
    pharmacy_ville: "",
    pharmacy_sexe: "",
    pharmacy_speciality: "",
  });
  const token = Cookies.get("jwt");

  const [inputs, setInputs] = useState({
    firstName: data.pharmacy_firstname || "",
    lastName: data.pharmacy_lastname || "",
    email: data.pharmacy_email || "",
    phone: data.pharmacy_phonenumber || "",
    address: data.pharmacy_address || "",
    city: data.pharmacy_ville || "",
    sex: data.pharmacy_sexe || "",
    speciality: data.pharmacy_speciality || "",
  });
  const getProfile = async () => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/profile",
        request
      );
      let data = await response.json();
      if (response.status === 200) {
        setData(data.data);

        setInputs({
          firstName: data.data.pharmacy_firstname || "",
          lastName: data.data.pharmacy_lastname || "",
          email: data.data.pharmacy_email || "",
          phone: data.data.pharmacy_phonenumber || "",
          address: data.data.pharmacy_address || "",
          city: data.data.pharmacy_ville || "",
          sex: data.data.pharmacy_sexe || "",
          speciality: data.data.pharmacy_speciality || "",
        });
      } else if (response.status === 409) {
        toast.error(d["error"][language]);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(d["error"][language]);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  const updatePharmacistProfile = async () => {
    const request = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        firstname: inputs.firstName,
        lastname: inputs.lastName,
        email: inputs.email,
        address: inputs.address,
        phone: inputs.phone,
        city: inputs.city,
        sexe: inputs.sex,
        speciality: inputs.speciality,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/update",
        request
      );
      let data = await response.json();
      if (response.status === 200) {
        setInputs({
          firstName: data.data?.pharmacy_firstname || "",
          lastName: data.data?.pharmacy_lastname || "",
          email: data.data?.pharmacy_email || "",
          address: data.data?.pharmacy_address || "",
          phone: data.data?.pharmacy_phonenumber || "",
          city: data.data?.pharmacy_ville || "",
          sex: data.data?.pharmacy_sexe || "",
          speciality: data.data?.pharmacy_speciality || "",
        });
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  function handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [passwordInputs, setPasswordInputs] = useState({
    oldPW: "",
    newPW: "",
  });
  function handlePasswordInputs(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setPasswordInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setPasswordChanged(true);
  }

  const modifyPassword = async () => {
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        password: passwordInputs.newPW,
        oldpassword: passwordInputs.oldPW,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/changePassword",
        request
      );
      if (response.status === 200) {
        let data = await response.json();
        toast.success(d["success"][language]);
      } else if (response.status === 409) {
        toast.error(data.message);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(d["error"][language]);
    }
  };
  const handleSubmit = (e) => {
    if (passwordChanged) {
      modifyPassword();
    }
    updatePharmacistProfile();
  };

  return (
    <Fragment>
      <div className="container card my-4 settings-container h-auto">
        <div
          className="py-5 mx-5"
          style={{ borderBottom: "1px solid #11236132" }}
        >
          <span className="settings-title titles">
            {" "}
            {d["account settings"][language]}
          </span>
        </div>

        <div className=" pt-5 pb-4" style={{ padding: "4.25rem" }}>
          <span className="settings-info-title titles">
            {" "}
            {d["info users"][language]}
          </span>
        </div>
        <form
          className="row h-auto"
          style={{ padding: " 0 4.25rem" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="col-lg-6 col-md-12">
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["lastname"][language]}
                </label>
                <input
                  value={inputs.lastName}
                  style={inputs.lastName === "" ? {} : { color: "black" }}
                  name="lastName"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={d["lastname"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">Email</label>
                <input
                  value={inputs.email}
                  style={inputs.email === "" ? {} : { color: "black" }}
                  name="email"
                  onChange={handleInputChange}
                  type="email"
                  placeholder="Email"
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["speciality"][language]}
                </label>
                <input
                  value={inputs.speciality}
                  style={inputs.speciality === "" ? {} : { color: "black" }}
                  name="speciality"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={d["speciality"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="form-row row mb-5 ">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["address"][language]}
                </label>
                <input
                  value={inputs.address}
                  style={inputs.address === "" ? {} : { color: "black" }}
                  name="address"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={d["address"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["firstname"][language]}
                </label>
                <input
                  value={inputs.firstName}
                  style={inputs.firstName === "" ? {} : { color: "black" }}
                  name="firstName"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={d["firstname"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["phone"][language]}
                </label>
                <input
                  value={inputs.phone}
                  style={inputs.phone === "" ? {} : { color: "black" }}
                  name="phone"
                  onChange={handleInputChange}
                  type="phone"
                  placeholder={d["phone"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="form-row row">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["sex"][language]}
                </label>
                <select
                  className="form-control settings-form-values"
                  onChange={handleInputChange}
                  value={inputs.sex}
                  style={inputs.sex === "" ? {} : { color: "black" }}
                  name="sex"
                >
                  {/* <option value="">{d["sex"][language]}</option> */}
                  <option value="male">{d["male"][language]}</option>
                  <option value="female">{d["female"][language]}</option>
                </select>
              </div>
            </div>
            <div className="form-row row mb-5">
              <div className="form-group col-md-12 ">
                <label className="settings-form-titles">
                  {d["city"][language]}
                </label>
                <input
                  value={inputs.city}
                  style={inputs.city === "" ? {} : { color: "black" }}
                  name="city"
                  onChange={handleInputChange}
                  type="text"
                  placeholder={d["city"][language]}
                  className="form-control settings-form-values"
                />
              </div>
            </div>
          </div>
          <div className=" row w-100 " style={{ padding: "0 15px" }}>
            <div className="col-12 mb-4 ">
              <span className="settings-info-title titles">
                {" "}
                {d["reset password"][language]}
              </span>
            </div>
          </div>
          <div className="row w-100">
            <div className="col-lg-6 col-md-12 w-100 pr-0">
              <div className="form-group col-md-12 pr-0 ">
                <label className="settings-form-titles">
                  {d["old password"][language]}
                </label>
                <input
                  value={passwordInputs.oldPW}
                  style={passwordInputs.oldPW === "" ? {} : { color: "black" }}
                  name="oldPW"
                  onChange={handlePasswordInputs}
                  type="password"
                  placeholder="******"
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 w-100 pr-0 mb-5">
              <div className="form-group col-md-12 pr-0 ">
                <label className="settings-form-titles">
                  {d["new password"][language]}
                </label>
                <input
                  value={passwordInputs.newPW}
                  style={passwordInputs.newPW === "" ? {} : { color: "black" }}
                  name="newPW"
                  onChange={handlePasswordInputs}
                  type="password"
                  placeholder="******"
                  className="form-control settings-form-values"
                />
              </div>
            </div>
            <div className="row w-100 p-0 m-0 ">
              <div className="col-6 p-0"></div>
              <div
                className="col-lg-6  col-md-12 d-flex justify-content-lg-end pr-0 "
                style={{ paddingLeft: "2rem", height: "7rem" }}
              >
                <button
                  className="btn  w-100 add-user btn-title"
                  type="submit"
                  style={{ background: "#3453DF" }}
                >
                  {d["save"][language]}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}

export default PharmacistProfile;
