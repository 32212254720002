import React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";

import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import d from "../../../resources/dictionary.json";
import { Link, useHistory } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { Input } from "reactstrap";
import { Dropdown } from "react-bootstrap";
/// Image
import profile from "../../../images/profile/doctor.png";
import doctor1 from "../../../images/profile/doctor1.png";
import doctorMale from "../../../images/profile/doctor_male.png";

import avatar from "../../../images/avatar/1.jpg";
import fr from "../../../icons/flags/fr.svg";
import en from "../../../icons/flags/gb.svg";
import { useContext } from "react";
import { LanguageContext } from "../../../resources/languageContext";
import "./Header.css";

const Header = ({ onNote, toggle, onProfile, onActivity, onNotification }) => {
  const token = Cookies.get("jwt");
  const user = jwt.decode(token);
  const history = useHistory();
  const { language, setLanguage } = useContext(LanguageContext);
  const handleLanguage = (lang) => {
    localStorage.setItem("userLanguage", lang);
    setLanguage(lang);
  };

  const [search, setsearch] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      history.push(`/patients_search:${search}`);
      setsearch("");
    }
  };
  const [searchExtend, setSearchExtend] = useState(false);
  const toggleSearch = () => {
    setSearchExtend(!searchExtend);
  };

  const logoutAndClear = () => {
    // Clear Cookies
    Cookies.remove("jwt");
    window.location.href="/login-page"
  };
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("form")
    ? filterName.filter((f) => f !== "form")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;

  var page_name =
    finalName.join(" ") === "" ? "Dashboard" : finalName.join(" ");
  if (page_name.includes("PT0000")) {
    page_name = d["patient details"][language];
  } else if (page_name.includes("search")) {
    page_name = d["search"][language];
  } else if (page_name.includes("detailedvalues")) {
    page_name = d["detailed values"][language];
  } else if (page_name.includes("account")) {
    page_name = d["account settings"][language];
  }
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {d[page_name]?.[language] || page_name}
              </div>
            </div>

            <ul className="navbar-nav header-right">
        
              <li className="nav-item">
                {searchExtend === true ? (
                  <>
                    <div
                      className="input-group search-box d-lg-inline-flex d-none"
                      onBlur={toggleSearch}
                    >
                      <form onSubmit={handleSubmit}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={d["search here"][language]}
                          value={search}
                          onChange={(e) => setsearch(e.target.value)}
                          onBlur={toggleSearch}
                        />
                      </form>
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.3479 0.0382632C8.95802 0.246077 6.75412 1.10467 4.78537 2.5867C4.22209 3.01326 3.01349 4.22186 2.58693 4.78514C0.366618 7.72733 -0.448225 11.2273 0.251776 14.7656C0.951776 18.2875 3.26506 21.4265 6.43693 23.1437C10.719 25.4734 15.8869 25.0851 19.7697 22.1539L20.2072 21.8203L23.1877 24.7898C24.8283 26.425 26.2393 27.8031 26.3213 27.8578C26.5565 28 26.9721 28.0328 27.2565 27.9234C27.5463 27.814 27.8526 27.4969 27.9455 27.207C28.0276 26.9554 27.9838 26.5289 27.858 26.3211C27.8033 26.239 26.4252 24.8281 24.7901 23.1875L21.8205 20.207L22.1541 19.7695C26.8135 13.5953 24.7354 4.7742 17.801 1.29607C15.876 0.328107 13.4369 -0.147675 11.3479 0.0382632ZM13.7104 2.38436C18.7033 3.1117 22.3674 7.32264 22.3674 12.332C22.3674 17.8773 17.8776 22.3672 12.3322 22.3672C8.53147 22.3672 5.10256 20.2562 3.36896 16.8437C2.12756 14.4047 1.94709 11.5281 2.88224 8.94139C3.63693 6.84139 5.1299 4.98748 6.98927 3.83358C7.85881 3.29217 8.93615 2.82733 9.84396 2.59217C10.976 2.30233 12.5729 2.21483 13.7104 2.38436Z"
                              fill="#3453DF"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="input-group search nav-element">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={toggleSearch}
                    >
                      <g clip-path="url(#clip0_241_3984)">
                        <path
                          d="M11.3479 0.0382652C8.95803 0.246078 6.75412 1.10467 4.78537 2.5867C4.22209 3.01327 3.0135 4.22186 2.58693 4.78514C0.366622 7.72733 -0.448222 11.2273 0.251778 14.7656C0.951778 18.2875 3.26506 21.4265 6.43693 23.1437C10.719 25.4734 15.8869 25.0851 19.7697 22.1539L20.2072 21.8203L23.1877 24.7898C24.8283 26.425 26.2393 27.8031 26.3213 27.8578C26.5565 28 26.9721 28.0328 27.2565 27.9234C27.5463 27.814 27.8526 27.4969 27.9455 27.207C28.0276 26.9555 27.9838 26.5289 27.858 26.3211C27.8033 26.239 26.4252 24.8281 24.7901 23.1875L21.8205 20.207L22.1541 19.7695C26.8135 13.5953 24.7354 4.7742 17.801 1.29608C15.876 0.32811 13.4369 -0.147673 11.3479 0.0382652ZM13.7104 2.38436C18.7033 3.1117 22.3674 7.32264 22.3674 12.332C22.3674 17.8773 17.8776 22.3672 12.3322 22.3672C8.53147 22.3672 5.10256 20.2562 3.36897 16.8437C2.12756 14.4047 1.94709 11.5281 2.88225 8.94139C3.63693 6.84139 5.1299 4.98748 6.98928 3.83358C7.85881 3.29217 8.93615 2.82733 9.84397 2.59217C10.976 2.30233 12.5729 2.21483 13.7104 2.38436Z"
                          fill="#112361"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_241_3984">
                          <rect width="28" height="28" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                )}
              </li>

              <li className="nav-item dropdown language_dropdown flag">
                <div className="basic-dropdown">
                  <Dropdown className="  nav-element">
                    <Dropdown.Toggle variant="white" className="language">
                      {language === "en" ? (
                        <>
                          <img src={en} alt="" />
                        </>
                      ) : language === "fr" ? (
                        <>
                          <img src={fr} alt="" />
                        </>
                      ) : (
                        "Lang"
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ minWidth: "auto" }}>
                      <Dropdown.Item
                        onClick={() => {
                          handleLanguage("en");
                        }}
                      >
                        <img src={en} alt="" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          handleLanguage("fr");
                        }}
                      >
                        <img src={fr} alt="" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  to={"#"}
                  className="nav-link bell bell-link nav-element"
                  onClick={() => onNote()}
                >
                  <svg
                    width="28"
                    className="notification-bell"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.9879 0.0875254C18.7847 0.300806 17.7293 0.85315 16.8652 1.71721C15.6894 2.89299 15.0715 4.49534 15.1644 6.11956C15.2465 7.54143 15.766 8.7719 16.6957 9.76721C17.4285 10.5492 18.1887 11.0414 19.173 11.3696C19.8457 11.5938 20.4363 11.6703 21.2621 11.6321C23.8543 11.5227 25.9543 9.80549 26.6433 7.22971C26.8019 6.62815 26.8402 5.44143 26.7199 4.79612C26.3699 2.94221 25.1012 1.30159 23.4441 0.5469C22.3504 0.0547123 21.109 -0.114819 19.9879 0.0875254Z"
                      fill="#ED1607"
                    />
                    <path
                      d="M11.5391 2.43357C9.78906 2.71248 8.18125 3.55467 6.93984 4.85623C5.84609 5.99373 5.15156 7.30076 4.80703 8.8867C4.69766 9.38982 4.68672 9.65232 4.64844 12.1133C4.61016 14.5523 4.59922 14.8422 4.48984 15.3234C4.12344 16.9914 3.38516 18.282 2.07812 19.5453C1.33984 20.2562 1.17578 20.5789 1.17578 21.3008C1.17578 21.7164 1.20312 21.8422 1.32344 22.1047C1.60781 22.7117 2.20937 23.1601 2.92031 23.2914C3.35781 23.3734 22.2742 23.3789 22.6953 23.2969C23.4062 23.1601 24.0406 22.6844 24.3141 22.0773C24.4398 21.7929 24.4672 21.6672 24.4617 21.3008C24.4617 20.5625 24.3359 20.3328 23.532 19.5344C23.1602 19.1625 22.7062 18.6648 22.5312 18.4242C21.7109 17.3195 21.2023 16.007 21.0437 14.607L20.9781 14.0164L20.4641 13.9781C17.1281 13.7429 14.2844 11.55 13.2398 8.39451C12.9445 7.49764 12.8352 6.81951 12.8352 5.85154C12.8352 4.73592 13.0156 3.79529 13.4203 2.78357C13.4969 2.5867 13.5625 2.40623 13.5625 2.38982C13.5625 2.3242 12.0203 2.35701 11.5391 2.43357Z"
                      fill="#112361"
                    />
                    <path
                      d="M8.53125 24.5219C8.53125 24.6149 8.78828 25.3477 8.89766 25.5664C9.45547 26.6711 10.4672 27.4969 11.6867 27.8469C12.2445 28.0055 13.3437 28.0164 13.8906 27.8688C14.7547 27.6391 15.6406 27.0703 16.1875 26.3977C16.4937 26.0258 16.9039 25.2438 17.0297 24.7844L17.1117 24.5H12.8187C10.4617 24.5 8.53125 24.511 8.53125 24.5219Z"
                      fill="#112361"
                    />
                  </svg>
                </Link>
              </li>

              <li
                className={`nav-item dropdown header-profile ${
                  toggle === "profile" ? "show" : ""
                }`}
                onClick={() => onProfile()}
              >
                <a className="nav-link" role="button" data-toggle="dropdown">
                  {/* <img
                    src={user?.doctor_sexe === "female" ? doctor1 : doctorMale}
                    width="20"
                    alt=""
                  /> */}
                  <div class="rounded-div">{(user?.pharmacy_lastname && user?.pharmacy_lastname?.length !==0) && user?.pharmacy_lastname[0]}</div>
                  <div className="header-info">
                    <span className="">
                      Dr {user?.pharmacy_lastname} {user?.pharmacy_firstname}
                    </span>
                    <p
                      className="fs-12 mb-0 font-face-gm"
                      style={{ fontWeight: "bold" }}
                    >
                      {d[user?.doctor_speciality]?.[language]}
                    </p>
                  </div>
                </a>
                <div
                  style={styleObj}
                  className={`dropdown-menu dropdown-menu-right ${
                    toggle === "profile" ? "show" : ""
                  }`}
                >
                  <Link to="/account" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ml-2">{d["profile"][language]} </span>
                  </Link>
                  <Link
                    onClick={logoutAndClear}
                    className="dropdown-item ai-icon"
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                      <polyline points="16 17 21 12 16 7"></polyline>
                      <line x1="21" y1="12" x2="9" y2="12"></line>
                    </svg>
                    <span className="ml-2">{d["logout"][language]} </span>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const styleObj = {
  right: "0px !important",
  left: "auto !important",
};

export default Header;
