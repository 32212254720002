import React, { useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import logo from "../../images/logo-full.png";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";

function NewPassword() {
  const { language } = useContext(LanguageContext);
  const { slug } = useParams();

  const [inputs, setInputs] = useState({
    password: "",
    confirm: "",
    error: false,
  });

  const handleChange = (e) => {
    setInputs((prvState) => ({ ...prvState, [e.target.name]: e.target.value }));
  };
  const submitHandler = (e) => {
    e.preventDefault();
    if (inputs.password !== inputs.confirm) {
      setInputs((prv) => ({ ...prv, error: true }));
    } else {
      setNewPassword();
    }
  };

  const setNewPassword = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        password: inputs.password,
      }),
    };
    try {
      let response = await fetch(
        `https://pharmacy.shls.care/api/doctor/new_password?token=${slug}`,
        request,
        { mode: "cors" }
      );
      let data = await response.json();
      if (response.status === 200) {
        window.location.href = "/login-page";
        // history.push("/dashboard");
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <div className="row justify-content-center  align-items-center h-80">
      <div className="col-md-6">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <div className="text-center mb-3">
                  <Link to={"/"}>
                    <img src={logo} alt="" />
                  </Link>
                </div>
                <h4 className="text-center mb-4 text-white">
                  {d["set new password"][language]}
                </h4>
                <form action="./" onSubmit={submitHandler}>
                  <div className="form-group">
                    <label className="mb-1 text-white">
                      <strong>{d["password"][language]}</strong>
                    </label>
                    <input
                      required
                      name="password"
                      placeholder="******"
                      type="password"
                      className="form-control"
                      value={inputs.password}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-1 text-white">
                      <strong>{d["confirm password"][language]}</strong>
                    </label>
                    <input
                      required
                      name="confirm"
                      placeholder="******"
                      type="password"
                      className="form-control"
                      value={inputs.confirm}
                      onChange={handleChange}
                    />
                  </div>
                  {inputs.error && (
                    <div className="validation-message">
                      <p style={{ color: "white" }}>* passwords don't match</p>
                    </div>
                  )}
                  <div className="text-center mt-4">
                    <button
                      type="submit"
                      className="btn bg-white text-primary btn-block"
                    >
                      {d["submit"][language]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPassword;
