import React from "react";

export default function About() {
  return (
    <>
      <div className="row m-4" style={{ gap: "1.87rem" }}>
        <div className="about-container col-lg col-md-12 col-sm-12 col-xs-12 p-0 ">
          <div className="card mb-0 about-card h-100 min-vh-75 w-100 ">
            <div className="card-body">
              <h4 className="text-center">PLATEFORME SOLIDE</h4>
              <p style={{opacity:"1"}}>
                Une solution Web bien conçue pour mieux convenir aux médecins
                désireux de suivre les activités de leurs patients diabétiques
                et d'évaluer leurs progrès tout au long du parcours
              </p>
            </div>
          </div>
        </div>
        <div className="about-container col-lg col-md-12 col-sm-12 col-xs-12 p-0 ">
          <div className="card mb-0 about-card h-100 min-vh-75 w-100">
            <div className="card-body">
              <h4 className="text-center">SÉCURITÉ</h4>
              <p style={{opacity:"1"}}>
                Nous prêtons une attention particulière à la sécurité, nos
                protocoles de connexion sont sécurisés grâce à TLS pour crypter
                les demandes entrantes / sortantes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row m-4" style={{ gap: "1.87rem" }}>
        <div className="about-container col-lg col-md-12 col-sm-12 col-xs-12 p-0 ">
          <div className="card mb-0 about-card h-100 min-vh-75 w-100 ">
            <div className="card-body ">
              <h4 className="text-center">SYSTÈME DE GESTION</h4>
              <p style={{opacity:"1"}}>
                Le suivi des patients est très simple et peut se faire en
                quelques clics seulement, un médecin peut parcourirnaviguer dans
                les profils et déterminer quels patients vont bien et lesquels
                ne le sont pas.
              </p>
            </div>
          </div>
        </div>
        <div className="about-container col-lg col-md-12 col-sm-12 col-xs-12 p-0 ">
          <div className="card mb-0 about-card h-100 min-vh-75 w-100 ">
            <div className="card-body">
              <h4 className="text-center">HISTORIQUE</h4>
              <p style={{opacity:"1"}}>
                Les activités de chaque patient seront synchronisées une fois
                connecté à l'internet et devraient être affichées
                quotidiennement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
