import Cookies from "js-cookie";
import React from "react";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Table } from "reactstrap";
import useFetch from "../../helpers/useFetch";
import Unit from "../components/custom/Unit";
import TypePill from "../components/custom/TypePill";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import { useContext } from "react";
import { Spinner } from "react-bootstrap";

function DetailedValues() {
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const { title, detail, selected } = location.state;
  let newTitle = title.toLowerCase();

  const token = Cookies.get("jwt");
  const request = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const { data } = useFetch(
    `https://pharmacy.shls.care/api/doctor/params/values?date=${selected}`,
    request
  );

  const derivedData = useMemo(
    () => dataFilter(data, title, detail),
    [data, title, detail]
  );

  if (!data) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <>
      <h3 className="mb-4">
        {title}: {d[`${detail}_values`][language]}
      </h3>
      <div className="detailed values card">
        <Table>
          <thead>
            <tr style={{ color: "black" }}>
              <th>#</th>
              <th>{d["firstname"][language]}</th>
              <th>{d["lastname"][language]}</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {derivedData?.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.patient_firstname}</td>
                <td>{item.patient_lastname}</td>
                <td>
                  <TypePill type={newTitle} />
                </td>
                <td>
                  {item[`${detail}_value`]} <Unit type={newTitle} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

function dataFilter(data, title, detail) {
  let newObj = [];
  const newTitle = title.toLowerCase();
  newObj = data[`${newTitle}_values`]?.[`${detail}_values`];
  return newObj;
}

export default DetailedValues;
