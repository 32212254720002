import React, { useState } from 'react';
import { MDBContainer, MDBRating } from 'mdbreact';

const Ratingpage = () => {
  const [basic] = useState([
    {
      tooltip: 'Very Bad'
    },
    {
      tooltip: 'Poor'
    },
    {
      tooltip: 'Ok',
      choosed: true
    },
    {
      tooltip: 'Good'
    },
    {
      tooltip: 'Excellent'
    }
  ]);

  return (
    <div className="rating-widget mb-4 text-center "> 
		<div className="rating-stars">
			<ul >
				<MDBRating data={basic}  className="justify-content-center" tag="li" />
			</ul>
		</div>
    </div>
  );
};

export default Ratingpage;


