import React from "react";

function Unit({ type }) {
  switch (type) {
    case "spo2":
      return <>%</>;
    case "hemoglobine":
      return <>g/dL</>;
    case "creatinine":
      return <>mg/L</>;
    case "weight":
      return <>kg</>;
    case "glycemia":
      return <>mg/dL</>;
    case "pressure":
      return <></>;
    case "thyroid":
      return <>mUl/L</>;
    case "temperature":
      return <>°</>;
    case "hba1c":
      return <>%</>;
    case "tsh":
      return <>uIU/mL</>;
    case "t3":
      return <>nmol/L</>;
    case "t4":
      return <>mIU/mL</>;
    default:
      return <>{type}</>;
  }
}

export default Unit;
