import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Badge } from "react-bootstrap";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";
import Unit from "./Unit";

export default function PendingPrescription({ item }) {
  const { language } = useContext(LanguageContext);
  const history = useHistory();
  return (
    <div
      className="recent-activity mb-2 "
      style={{ cursor: "pointer" }}
      onClick={() =>
        history.push({
          pathname: `/prescriptions`,
          state: { tab: "pending", activeTab: 1 },
        })
      }
    >
      <div
        className="d-flex justify-content-between align-items-center pb-0"
        style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
      >
        <span className="client">
          {item.patient_lastname} {item.patient_firstname}
        </span>
        <span className="client-date">{item.created_at.split("T")[0]}</span>
        <div className="btn-pending">
          <span>
            <Badge className="pending-client">{d["pending e"][language]}</Badge>
          </span>
        </div>
      </div>
      {/* <div className="d-flex justify-content-between">
        <span className="">{item.created_at.split("T")[0]}</span>
        <span>

          {" "}
          
        </span>
      </div> */}
      <hr />
    </div>
  );
}
