import React, { useContext } from "react";
import { Badge } from "react-bootstrap";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";

export default function StatusBadge({ status, variant }) {
  const { language } = useContext(LanguageContext);

  switch (status) {
    case "accepted":
      return (
        <span className="btn  btn-success rounded">
          {d["accepted"][language]}
        </span>
        // <Badge variant={"outline-success badge-lg badge-rounded"}>
        //   {d["accepted"][language]}
        // </Badge>
      );

    case "rejected":
      return (
        <span className="btn btn-danger rounded">
          {d["rejected"][language]}
        </span>
        // <Badge variant={"outline-danger badge-lg badge-rounded"}>
        //   {d["rejected"][language]}
        // </Badge>
      );

    case "sending":
      return (
        <span className="btn btn-info rounded">{d["pending"][language]}</span>
        // <Badge variant={"outline-warning badge-lg badge-rounded"}>
        //   {d["pending"][language]}
        // </Badge>
      );

    default:
      return (
        <span className="btn btn-success rounded">
          {d["accepted"][language]}
        </span>
      );
  }
}
