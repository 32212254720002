import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useHistory, useParams } from "react-router-dom";
import Cookies from "js-cookie";

import { Spinner, Tab, Nav, Modal } from "react-bootstrap";


import patient from "../../../images/avatar/patient.png";
import woman from "../../../images/avatar/woman.png";


export default function PatientsSearch() {
 
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const [fetchData, setFetchData] = useState("");
  const { data } = fetchData;

  const history = useHistory();

  const token = Cookies.get("jwt");

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };
      try {
        let response = await fetch(
          `https://pharmacy.shls.care/api/pharmacy/patients/patient?search=${slug}`,
          request,
          { mode: "cors" }
        );
        const data = await response.json();
        if (response.status === 200) {
          setFetchData(data);
        } else {
        }
        setLoading(false);
      } catch (err) {
        console.log(err.message);
      }
    };
    if (!ignore) {
      fetchData();
    }

    return () => {
      setLoading(false);
      window.scrollTo(0, 0);
      ignore = true;
    };
  }, []);

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <>
      <Tab.Container defaultActiveKey={1}>
        <div className="row">
          <div className="col-xl-12 col-xxl-12 col-lg-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="navpills-1"
                role="tabpanel"
              >
                <div
                  className="row loadmore-content"
                  id="RecentActivitiesContent"
                >
                  {data?.map((item, index) => (
                    <div
                      className="col-md-4  col-sm-6"
                      key={item.patient_id}
                    >
                      <div className="card contact-bx">
                        <div
                          className="card-body"
                          onClick={() => {
                            history.push({
                              pathname: `/patient:${item.patient_id}`,
                              state: item,
                            });
                          }}
                        >
                          <div className="media">
                            <div className="image-bx mr-3">
                              <img
                                src={
                                  item.patient_sexe === "female"
                                    ? woman
                                    : patient
                                }
                                alt="User"
                                className="rounded-circle"
                                width="90"
                              />
                            </div>
                            <div className="media-body">
                              <h6 className="fs-20 font-w600 mb-0">
                                <span
                                  // to={{
                                  //   pathname: `/patient:${item.patient_id}`,
                                  //   state: item,
                                  // }}
                                  className="text-black"
                                >
                                  {item.patient_lastname}{" "}
                                  {item.patient_firstname}
                                </span>
                              </h6>
                              <p className="fs-14 my-1">{item.patient_email}</p>
                              <p className="fs-14 my-1">
                                {item.patient_phonenumber}
                              </p>
                              <p className="fs-14">{item.patient_ville}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab.Container>
    </>
  );
}
