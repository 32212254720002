import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
// import logo from "../../../images/logo full.png";
import logo from "../../../images/LOGOSH.png";
import d from "../../../resources/dictionary.json";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { LanguageContext } from "../../../resources/languageContext";
import loginImg from "../../../images/login-img.svg";

const LoginJWT = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [loginData, setLoginData] = useState({});
  const history = useHistory();
  const handleBlur = (e) => {
    const newLoginData = { ...loginData };
    newLoginData[e.target.name] = e.target.value;
    setLoginData(newLoginData);
  };

  const login = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/auth/pharmacy/login",
        request,
        {
          mode: "cors",
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        Cookies.set("jwt", data.accessToken, { expires: 15 });
        // window.location.href = `${process.env.PUBLIC_URL}/`
        window.location.href = `${process.env.PUBLIC_URL}/`;
        // history.push("/dashboard");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    login();
  };
  return (
    <>
      <div className="row login-container" style={{}}>
        <div className="col-xl col-lg col-md-12 p-0 h-100 w-100 login-img-section d-flex align-items-center justify-content-center">
          <img src={loginImg} alt="" className="  w-100" />
        </div>
        <div className="col-xl col-lg col-md-12 d-flex flex-column justify-content-center h-100 w-100 align-items-center">
          <div
            className="row w-auto text-center mb-5"
            style={{ height: "8.75rem" }}
          >
            <span className="login-title col-12 h-auto text-left">
              {d["welcome back"][language]} 👋
            </span>
            <span className="login-description col-12 h-auto text-left">
              Today is a new day. It's your day. You shape it. Sign in to start
              managing your patients.
            </span>
          </div>{" "}
          <div></div>
          <form
            action=""
            onSubmit={submitHandler}
            className="w-100 d-flex flex-column justify-content-start align-items-center"
          >
            <div className="form-group w-50">
              <label className="login-from-titles mb-3">Email</label>
              <input
                type="email"
                className="form-control settings-form-values"
                placeholder="email@email.com"
                name="email"
                required
                onChange={handleBlur}
                style={{ color: "black" }}
              />
            </div>
            <div className="form-group w-50 mb-4">
              <label className="mb-3 login-from-titles ">
                {d["password"][language]}
              </label>
              <input
                required
                type="password"
                className="form-control settings-form-values"
                placeholder="******"
                name="password"
                onChange={handleBlur}
                style={{ color: "black" }}
              />
            </div>
            <div className="form-row d-flex justify-content-center w-100 mb-4">
              <div className="form-group w-50">
                <Link className="forgot-password" to="/forgot-password-page">
                  {d["forgot password"][language]}?
                </Link>
              </div>
            </div>
            <div className="text-center w-50 mb-4">
              <button
                type="submit"
                className="btn add-user login-button-shadow w-100 "
                onClick={() => submitHandler}
              >
                {d["sign in"][language]}
              </button>
            </div>
            <div className="d-flex w-50 align-items-center mb-4">
              <div className=" w-100 mr-3 login-divider login-button-shadow"></div>
              <span>Or</span>
              <div className=" w-100 ml-3 login-divider login-button-shadow"></div>
            </div>
            <div className="text-center w-50 mb-3">
              <Link
                to={"#"}
                data-toggle="modal"
                data-target="#addOrderModal"
                className="btn btn-google-user login-button-shadow  google-user w-100"
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3111_2174)">
                    <path
                      d="M28.2268 14.3225C28.2268 13.3708 28.1496 12.414 27.985 11.4777H14.7798V16.8689H22.3418C22.028 18.6076 21.0197 20.1458 19.5433 21.1232V24.6212H24.0548C26.7041 22.1829 28.2268 18.5819 28.2268 14.3225Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M14.7798 28.001C18.5557 28.001 21.7399 26.7612 24.06 24.6212L19.5485 21.1231C18.2933 21.9771 16.6729 22.4606 14.7849 22.4606C11.1325 22.4606 8.03572 19.9965 6.92456 16.6837H2.26904V20.2898C4.64567 25.0173 9.48639 28.001 14.7798 28.001Z"
                      fill="#34A853"
                    />
                    <path
                      d="M6.91941 16.6837C6.33297 14.9449 6.33297 13.0621 6.91941 11.3234V7.71729H2.26904C0.283368 11.6732 0.283368 16.3339 2.26904 20.2898L6.91941 16.6837Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M14.7798 5.54127C16.7757 5.51041 18.7048 6.26146 20.1504 7.64012L24.1474 3.64305C21.6165 1.26642 18.2573 -0.0402103 14.7798 0.000943444C9.48638 0.000943444 4.64567 2.98459 2.26904 7.71728L6.91942 11.3234C8.02542 8.00536 11.1274 5.54127 14.7798 5.54127Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3111_2174">
                      <rect
                        width="28"
                        height="28"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <span className="btn-title">
                  {d["sign in with google"][language]}
                </span>
              </Link>
            </div>
            <div className="text-center w-50 mb-5">
              <Link
                to={"#"}
                data-toggle="modal"
                data-target="#addOrderModal"
                className="btn btn-google-user login-button-shadow  google-user w-100"
              >
                <svg
                  width="29"
                  height="28"
                  viewBox="0 0 29 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_3111_5189)">
                    <path
                      d="M28.5 14C28.5 6.26801 22.232 0 14.5 0C6.76801 0 0.5 6.26801 0.5 14C0.5 20.9877 5.61957 26.7796 12.3125 27.8299V18.0469H8.75781V14H12.3125V10.9156C12.3125 7.40687 14.4027 5.46875 17.6005 5.46875C19.1318 5.46875 20.7344 5.74219 20.7344 5.74219V9.1875H18.9691C17.23 9.1875 16.6875 10.2668 16.6875 11.375V14H20.5703L19.9496 18.0469H16.6875V27.8299C23.3804 26.7796 28.5 20.9877 28.5 14Z"
                      fill="#3453DF"
                    />
                    <path
                      d="M19.9496 18.0469L20.5703 14H16.6875V11.375C16.6875 10.2679 17.23 9.1875 18.9691 9.1875H20.7344V5.74219C20.7344 5.74219 19.1323 5.46875 17.6005 5.46875C14.4027 5.46875 12.3125 7.40688 12.3125 10.9156V14H8.75781V18.0469H12.3125V27.8299C13.762 28.0567 15.238 28.0567 16.6875 27.8299V18.0469H19.9496Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_3111_5189">
                      <rect
                        width="28"
                        height="28"
                        fill="white"
                        transform="translate(0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <span className="btn-title">
                  {d["sign in with facebook"][language]}
                </span>
              </Link>
            </div>
            <p className="signup-title">
              {d["you don't have an account"][language]}?{" "}
              <Link className="signup-link" to="/register-page">
                {d["sign up"][language]}
              </Link>
            </p>
          </form>
          <span className="rights">© 2023 ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </>
  );
};

export default LoginJWT;
