import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  Spinner,
  Tab,
  Nav,
  Modal,
  Badge,
  Button,
} from "react-bootstrap";

//** Import Image */

import patientPhoto from "../../../images/avatar/patient.png";
import woman from "../../../images/avatar/woman.png";

import Cookies from "js-cookie";
import { LanguageContext } from "../../../resources/languageContext";
import d from "../../../resources/dictionary.json";

import { toast } from "react-toastify";
import Swal from "sweetalert2";
import user from "../../../images/no-client.svg";

function PatientDetails() {
  const { language } = useContext(LanguageContext);
  const location = useLocation();
  const { id } = useParams();
  const history = useHistory();
  const { state: patient } = useLocation() || {};
  const [accepted, setAccepted] = useState([]);
  const [pending, setPending] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState("accepted");
  const [age, setAge] = useState("");
  const [backgroundColor, setBackgroundColor] = useState(null);

  const [notifyMsg, setNotifyMsg] = useState({
    title: "",
    message: "",
  });
  const [showModal, setShowModal] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        Notification_content: notifyMsg.message,
        Notification_title: notifyMsg.title,
        patient_id: id,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/notification/push",
        request
      );
      if (response.status === 200) {
        toast.success(d["message sent"][language]);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowModal(false);
    }
  };
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    setNotifyMsg((prvMsg) => ({
      ...prvMsg,
      [name]: value,
    }));
  };
  const token = Cookies.get("jwt");

  const getDate = (date) => {
    if (date) {
      let resultat = date.split("T");

      return resultat[0];
    }
  };
  function calculateAge(birthday) {
    const today = new Date();
    const birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }

  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      const request = {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "Origin, X-requested-With, Content-Type, Accept",
          Authorization: "Bearer " + token,
        },
      };
      try {
        let response = await fetch(
          "https://pharmacy.shls.care/api/pharmacy/patient?id=" + id,
          request,
          {
            mode: "cors",
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          setData(data);
          console.log("datatattaa", data);
          const age = calculateAge(data.user.patient_birthday);
          setAge(age);
          setPending(
            data.data.filter((prescription) => {
              return prescription.status == "pending";
            })
          );
          setAccepted(
            data.data.filter((prescription) => {
              return prescription.status == "accepted";
            })
          );
          setRejected(
            data.data.filter((prescription) => {
              return prescription.status == "rejected";
            })
          );
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };
    if (!ignore) {
      fetchData();
    }

    return () => {
      setLoading(false);
      window.scrollTo(0, 0);
      ignore = true;
    };
  }, []);

  const handleUnfollow = () => {
    Swal.fire({
      text: d["do you really want to stop following this patient ?"][language],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: " #3453df",

      cancelButtonColor: "#ED1607",
      confirmButtonText: d["confirm"][language],
    }).then((result) => {
      if (result.isConfirmed) {
        unfollowPatient();
      }
    });
  };

  const unfollowPatient = async () => {
    const request = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        patient_id: id,
        followId: patient.follow_pharmacy_id,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/unfollow",
        request,
        {
          mode: "cors",
        }
      );
      if (response.status === 200) {
        toast.success(d["success"][language]);
        history.push("/patients");
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const getStatusBadge = (status, language, d) => {
    let badgeColor = null;
    if (status === "accepted") {
      badgeColor = "#2BC155";
    } else if (status === "rejected") {
      badgeColor = "#ED1607";
    } else {
      badgeColor = "#FFA500";
    }

    return {
      badge: (
        <Badge
          className={"btn w-75"}
          style={{
            backgroundColor: badgeColor,
            height: "3.5rem",
            padding: "1.5rem",
            fontFamily: "Gilroy-Medium , sans-serif ",
            fontSize: "1.125rem",
          }}
        >
          {d[status + " e"][language]}
        </Badge>
      ),
      color: badgeColor,
    };
  };

  const handleClick = (status) => {
    const { color } = getStatusBadge(status, language, d);
    setBackgroundColor(color);
  };
  let dateTime = new Date();
  const timeCalculator = (date) => {
    if (!date) {
      return d["not mentioned"][language];
    }
    dateTime = moment(dateTime).utc().format();
    let diff = moment(dateTime).diff(date, "seconds");
    if (diff < 60) {
      return diff + d["seconds"][language];
    } else if (diff < 3600) {
      return (diff / 60).toFixed(0) + " " + d["minutes"][language];
    } else if (diff < 86400) {
      return (diff / 3600).toFixed(0) + " " + d["hours"][language];
    } else {
      return (diff / 86400).toFixed(0) + " " + d["days"][language];
    }
  };

  return (
    <Fragment>
      <div className="m-5">
        <ol
          className="breadcrumb p-0 mb-4"
          style={{ background: "transparent" }}
        >
          <li className="breadcrumb-item modal-title">
            {d["patients"][language]}
          </li>
          <li
            className="breadcrumb-item active modal-title"
            style={{ color: "#11236164" }}
          >
            {data?.user?.patient_lastname} {data?.user?.patient_firstname}
          </li>
        </ol>
        <div className="card contact-bx w-auto patient-info-card p-5 row d-flex flex-row ">
          <div
            className="col-xl-9 col-lg-9 col-md-12 row mb-md-4"
            style={{ gap: ".875rem" }}
          >
            <div className=" col-lg-1 col-md-2  user-img w-auto h-auto p-0">
              <img
                src={(() => {
                  if (data?.user?.profil_picture != null) {
                    return (
                      "https://pharmacy.shls.care/prescription/" +
                      data?.user?.profil_picture
                    );
                  } else {
                    if (
                      data?.user?.patient_sexe === "male" ||
                      data?.user?.patient_sexe === "Male"
                    ) {
                      return patientPhoto;
                    } else {
                      return woman;
                    }
                  }
                })()}
                className="rounded-circle "
                alt="profile"
                style={{ width: "5rem", height: "5rem" }}
              />
            </div>
            <div className="col-lg-10 col-md-9 h-100">
              <div
                className="row d-flex justify-content-between align-items-center mb-4"
                style={{ height: "5rem" }}
              >
                <div className="col-5 d-flex flex-column">
                  <span className="patient-user-name">
                    {data?.user?.patient_lastname}{" "}
                    {data?.user?.patient_firstname}
                  </span>
                  <span className="patient-ville patient-info-title">
                    {data?.user?.patient_ville}
                  </span>
                </div>
                <div className="col  row">
                  <div className=" col d-flex flex-column align-items-center p-0 info">
                    <span className="patient-info">{age}</span>
                    <span className="patient-ville patient-info-title">
                      Age
                    </span>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #3453DF32",
                      height: "3rem",
                      margin: "auto 0",
                    }}
                    className="px-0"
                  ></div>
                  <div className=" col d-flex flex-column align-items-center p-0 info">
                    <div className="patient-info">
                      {data?.user?.patient_sexe === "Male" ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-gender-male"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            stroke-width="1"
                            d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          fill="currentColor"
                          class="bi bi-gender-female"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            stroke-width="1"
                            d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5"
                          />
                        </svg>
                      )}
                    </div>

                    <span className="patient-ville patient-info-title">
                      Sexe
                    </span>
                  </div>
                  <div
                    style={{
                      borderRight: "1px solid #3453DF32",
                      height: "3rem",
                      margin: "auto 0",
                    }}
                    className="px-0"
                  ></div>

                  <div className=" col p-0 d-flex flex-column align-items-center info">
                    <span className="patient-info">
                      {data?.user?.patient_height}
                    </span>
                    <span className="patient-ville">
                      {d["height"][language]}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #3453DF32" }}></div>
              <div className="row  mt-4" style={{ gap: " 0 2rem" }}>
                <div className="col-xl col-lg-10 col-md-12 d-flex flex-column p-0">
                  <div
                    className="d-flex justify-content-between user-data px-3 px-sm-0 "
                    
                  >
                    <span
                      className="user-id user-opacity w-25 text-left text-nowrap"
                      style={{ opacity: "48%" }}
                    >
                      {d["birthday"][language]}:
                    </span>{" "}
                    <span className="user-id  text-right w-75 text-nowrap">
                      {new Date(
                        data?.user?.patient_birthday
                      ).toLocaleDateString()}
                    </span>
                  </div>
                  <div
                    className="d-flex justify-content-between user-data px-3 px-sm-0 "
                    
                  >
                    <span
                      className="user-id user-opacity w-25 text-left"
                      style={{ opacity: "48%" }}
                    >
                      {d["city"][language]}:
                    </span>{" "}
                    <span className="user-id  text-right w-75 text-nowrap">
                      {data?.user?.patient_ville}
                    </span>
                  </div>
                </div>
                <div className="col-xl col-lg-10 col-md-12 d-flex flex-column p-0">
                  <div className="d-flex justify-content-between  user-data p-0 ">
                    <span
                      className="user-id user-opacity w-25 text-left"
                      style={{ opacity: "48%" }}
                    >
                      Mobile:
                    </span>{" "}
                    <span className="user-id  text-right w-75 text-nowrap">
                      {data?.user?.patient_phonenumber}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between user-data p-0 ">
                    <span
                      className="user-id user-opacity text-left w-25 "
                      style={{ opacity: "48%" }}
                    >
                      Email:
                    </span>{" "}
                    <span className="user-id text-right w-75 text-nowrap ">
                      {data?.user?.patient_email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ borderRight: "1px solid #3453DF32", margin: "0 2rem" }}
          ></div>
          <div className=" col-xl col-lg col-md-12 d-flex flex-column   align-items-lg-center justify-content-center  align-items-sm-end  align-items-xs-end ">
            <button
              className="btn add-user w-75 w-md-50 w-md-50 mb-4 "
              onClick={() => setShowModal(true)}
            >
              {d["notify"][language]}
            </button>
            <button
              className="btn user-signale w-75 w-md-50  "
              onClick={handleUnfollow}
            >
              {d["stop following"][language]}
            </button>
          </div>
        </div>
        <Tab.Container defaultActiveKey={1}>
          <div className="row d-flex flex-column m-0 p-0 prescriptions-dash pb-4 pt-5 h-auto min-vh-25">
            <Nav as="ul" className="nav nav-tabs tabs-lg bg-white h-auto ">
              <Nav.Item as="li" key={1} className="prescriptions ">
                <Nav.Link
                  eventKey={1}
                  onClick={() => {
                    setTab("accepted");
                  }}
                >
                  <span>{accepted?.length}</span>
                  {d["accepted e"][language]}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2} className="prescriptions">
                <Nav.Link
                  eventKey={2}
                  onClick={() => {
                    setTab("pending");
                  }}
                >
                  <span>{pending?.length}</span>
                  {d["pending e"][language]}
                </Nav.Link>
              </Nav.Item>

              <Nav.Item as="li" key={3} className="prescriptions">
                <Nav.Link
                  eventKey={3}
                  onClick={() => {
                    setTab("Rejected");
                  }}
                >
                  <span>{rejected?.length}</span>
                  {d["rejected"][language]}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="row  card h-auto mb-0 perscriprions-card details">
            <div className="col-xl-12 col-xxl-12 col-lg-12 p-0">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="navpills-1"
                  role="tabpanel"
                >
                  <Tab.Content>
                    <Tab.Pane eventKey={1} key={1}>
                      {accepted?.length ? (
                        <Table responsive className="prescriptions-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>{d["remark"][language]}</th>
                              <th>{d["status"][language]}</th>
                              <th>{d["duration"][language]}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <div>
                              {accepted?.map((item) => (
                                <tr key={item.patient_id}>
                                  <td className="table-patient">
                                    {item.prescription_id}
                                  </td>
                                  <td>
                                    {item.prescription_note ? (
                                      <span>{item.prescription_note}</span>
                                    ) : (
                                      <span> {d["no remark"][language]}</span>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      href={`https://pharmacy.shls.care/prescription/${item.prescription_name}`}
                                      onClick={() => handleClick(item.status)}
                                    >
                                      {
                                        getStatusBadge(item.status, language, d)
                                          .badge
                                      }
                                    </a>
                                  </td>
                                  <td>{timeCalculator(item.created_at)}</td>
                                </tr>
                              ))}
                            </div>
                          </tbody>
                        </Table>
                      ) : (
                        <div style={{ height: "0rem" }}>
                          <div className="card no-client-card d-flex flex-column justify-content-center align-items-center ">
                            <img src={user} alt="" className="  w-auto" />

                            <span
                              className="no-client-title"
                              style={{ paddingBottom: "2.5rem" }}
                            >
                              {d["no pending perscription"][language]}
                            </span>
                            <div className="" style={{ width: "18rem" }}>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                data-toggle="modal"
                                data-target="#addOrderModal"
                                className="btn  add-user"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_2801_3149)">
                                    <path
                                      d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2801_3149">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <span className="btn-title">
                                  {d["new appointment"][language]}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey={2} key={2}>
                      {pending?.length ? (
                        <Table responsive className="prescriptions-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>{d["remark"][language]}</th>
                              <th>{d["status"][language]}</th>
                              <th>{d["duration"][language]}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <div>
                              {pending?.map((item) => (
                                <tr key={item.patient_id}>
                                  <td className="table-patient">
                                    {item.prescription_id}
                                  </td>
                                  <td>
                                    {item.prescription_note ? (
                                      <span>{item.prescription_note}</span>
                                    ) : (
                                      <span> {d["no remark"][language]}</span>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      href={`https://pharmacy.shls.care/prescription/${item.prescription_name}`}
                                      onClick={() => handleClick(item.status)}
                                    >
                                      {
                                        getStatusBadge(item.status, language, d)
                                          .badge
                                      }
                                    </a>
                                  </td>
                                  <td>{timeCalculator(item.created_at)}</td>
                                </tr>
                              ))}
                            </div>
                          </tbody>
                        </Table>
                      ) : (
                        <div style={{ height: "0rem" }}>
                          <div className="card no-client-card d-flex flex-column justify-content-center align-items-center ">
                            <img src={user} alt="" className="  w-auto" />

                            <span
                              className="no-client-title"
                              style={{ paddingBottom: "2.5rem" }}
                            >
                              {d["no pending perscription"][language]}
                            </span>
                            <div className="" style={{ width: "18rem" }}>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                data-toggle="modal"
                                data-target="#addOrderModal"
                                className="btn  add-user"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_2801_3149)">
                                    <path
                                      d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2801_3149">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <span className="btn-title">
                                  {d["new appointment"][language]}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>

                    <Tab.Pane eventKey={3} key={3}>
                      {rejected?.length ? (
                        <Table responsive className="prescriptions-table">
                          <thead>
                            <tr>
                              <th>ID</th>
                              <th>{d["remark"][language]}</th>
                              <th>{d["status"][language]}</th>
                              <th>{d["duration"][language]}</th>
                            </tr>
                          </thead>

                          <tbody>
                            <div>
                              {rejected?.map((item) => (
                                <tr key={item.patient_id}>
                                  <td className="table-patient">
                                    {item.prescription_id}{" "}
                                  </td>
                                  <td>
                                    {item.prescription_note ? (
                                      <span>{item.prescription_note}</span>
                                    ) : (
                                      <span> {d["no remark"][language]}</span>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                      }}
                                      href={`https://pharmacy.shls.care/prescription/${item.prescription_name}`}
                                      onClick={() => handleClick(item.status)}
                                    >
                                      {
                                        getStatusBadge(item.status, language, d)
                                          .badge
                                      }
                                    </a>
                                  </td>
                                  <td>{timeCalculator(item.created_at)}</td>
                                </tr>
                              ))}
                            </div>
                          </tbody>
                        </Table>
                      ) : (
                        <div style={{ height: "0rem" }}>
                          <div className="card no-client-card d-flex flex-column justify-content-center align-items-center ">
                            <img src={user} alt="" className="  w-auto" />

                            <span
                              className="no-client-title"
                              style={{ paddingBottom: "2.5rem" }}
                            >
                              {d["no pending perscription"][language]}
                            </span>
                            <div className="" style={{ width: "18rem" }}>
                              <Link
                                to={"#"}
                                onClick={() => {
                                  setShowModal(true);
                                }}
                                data-toggle="modal"
                                data-target="#addOrderModal"
                                className="btn  add-user"
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g clip-path="url(#clip0_2801_3149)">
                                    <path
                                      d="M9.7875 2.24625C10.6172 2.24625 11.4286 2.56828 12.0141 3.12984C12.3605 3.46406 12.4852 3.6314 12.6792 4.02234C13.0017 4.67343 13.1034 5.33953 12.9895 6.05906C12.8766 6.74812 12.5231 7.39687 11.9953 7.88531C11.4717 8.37 10.8112 8.66437 10.1344 8.715C10.0364 8.7225 9.9375 8.72625 9.84047 8.72625C8.63766 8.72625 7.59891 8.14734 6.98953 7.13812C6.84047 6.88593 6.72328 6.59062 6.62062 6.21L6.61781 6.19968L6.615 6.18937C6.60516 6.15422 6.60047 6.13547 6.59812 6.12562C6.58781 6.01547 6.58969 5.6264 6.59062 5.49656V5.49V5.48343C6.59062 5.30156 6.59062 4.92468 6.60094 4.83656C6.60281 4.82578 6.61078 4.79203 6.63844 4.69078L6.63984 4.68562L6.64125 4.68047C6.71812 4.39125 7.035 3.74812 7.17609 3.56062C7.69078 2.88937 8.52281 2.39765 9.34781 2.27718C9.49172 2.25703 9.63984 2.24625 9.7875 2.24625ZM9.7875 -0.00375175C9.5325 -0.00375175 9.27703 0.0145283 9.02344 0.0515594C7.6125 0.25781 6.25312 1.06406 5.38594 2.19843C5.05781 2.62968 4.61719 3.53906 4.46719 4.10156C4.35 4.53281 4.34062 4.64062 4.34062 5.48437C4.33594 6.29531 4.35 6.44531 4.44844 6.79687C4.60781 7.3875 4.80469 7.86562 5.05781 8.29218C6.08062 9.99422 7.84781 10.9767 9.84047 10.9767C9.99328 10.9767 10.1475 10.9711 10.3031 10.9594C12.7453 10.7766 14.8125 8.86406 15.2109 6.41718C15.3984 5.24062 15.225 4.09218 14.6953 3.02343C14.3859 2.4 14.1047 2.02031 13.575 1.50937C12.5644 0.538591 11.182 -0.00375175 9.7875 -0.00375175Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M19.2375 4.64062C18.9468 4.75312 18.6093 5.07656 18.4593 5.38125C18.3609 5.57812 18.3468 5.68594 18.3281 6.39844L18.3046 7.19531L17.5078 7.21875C16.5937 7.24687 16.4343 7.29375 16.0921 7.63594C15.8156 7.9125 15.6937 8.19375 15.6656 8.59687C15.6281 9.20625 15.9468 9.75 16.4906 10.0172C16.7437 10.1437 16.7953 10.1484 17.5406 10.1625L18.3187 10.1812L18.3375 10.9828C18.3562 11.9016 18.3984 12.0516 18.7593 12.4125C19.0734 12.7312 19.3078 12.8203 19.8281 12.8203C20.3484 12.8203 20.55 12.7359 20.8875 12.3937C21.2156 12.0562 21.2812 11.8078 21.2812 10.9219V10.1812L22.0921 10.1625C23.0015 10.1437 23.1609 10.1016 23.5312 9.76875C24.2812 9.09375 24.1078 7.83281 23.1984 7.35C23.0109 7.25156 22.9125 7.24219 22.1484 7.21875L21.3046 7.19531L21.2812 6.39844C21.2578 5.67656 21.2484 5.57812 21.1453 5.37187C21.0046 5.09062 20.7468 4.81875 20.475 4.68281C20.1843 4.52812 19.5656 4.50937 19.2375 4.64062Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M9.75511 13.7887C10.0087 13.7958 10.8206 13.8722 11.1539 13.9327L11.1571 13.9331L11.1604 13.9336C12.7059 14.2087 14.1046 14.903 15.2053 15.9412C16.2867 16.9612 17.0109 18.2498 17.3001 19.6692C17.3451 19.8895 17.347 19.8984 17.3315 20.2439L17.331 20.25V20.2561C17.3146 20.677 17.31 20.6859 17.1942 20.9217L17.1909 20.9283L17.1876 20.9348C17.0657 21.187 16.8689 21.382 16.6026 21.5152C16.3945 21.6173 16.2843 21.6689 15.6754 21.7078C15.3562 21.7284 13.1812 21.75 9.77995 21.75C6.48651 21.75 4.15448 21.7294 3.71901 21.7092C3.29714 21.6511 2.95167 21.5222 2.7187 21.3356C2.51854 21.1753 2.38683 20.9648 2.3048 20.6747C2.26073 20.4717 2.25323 19.8741 2.3273 19.5267C2.49558 18.7852 2.86495 17.91 3.26948 17.2936C3.9262 16.298 4.83698 15.4509 5.90386 14.8439C6.97495 14.2345 8.1848 13.8773 9.40261 13.8108L9.4162 13.8098L9.4298 13.8089C9.49917 13.8042 9.57558 13.7995 9.64167 13.7958C9.68433 13.7934 9.72276 13.7911 9.75511 13.7887ZM9.7298 11.5383C9.70026 11.5383 9.67542 11.5387 9.6562 11.5406C9.59058 11.5453 9.42183 11.5547 9.2812 11.5641C6.0937 11.7375 3.12651 13.425 1.39214 16.0547C0.834326 16.9031 0.356201 18.0422 0.131201 19.0406C-0.0141113 19.7062 -0.0141113 20.7094 0.131201 21.2531C0.548389 22.7766 1.7437 23.7328 3.49214 23.9484C3.76636 23.9822 6.78839 24 9.78089 24C12.5671 24 15.3276 23.985 15.8203 23.9531C16.6265 23.9016 17.0484 23.8031 17.6015 23.5312C18.3093 23.1797 18.8718 22.6219 19.214 21.9141C19.4812 21.3703 19.5515 21.0656 19.5796 20.3437C19.6031 19.8141 19.589 19.6312 19.5046 19.2188C18.7265 15.3984 15.5859 12.4359 11.5546 11.7187C11.1004 11.6367 10.0804 11.5383 9.7298 11.5383Z"
                                      fill="white"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_2801_3149">
                                      <rect
                                        width="24"
                                        height="24"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>

                                <span className="btn-title">
                                  {d["new appointment"][language]}
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </div>
          </div>
        </Tab.Container>
        <Modal
          centered
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          className="modal fade"
        >
          <div className="modal-header">
            <h5 className="modal-title">{d["send notification"][language]}</h5>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
              }}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="settings-form-titles">
                  {d["title"][language]}
                </label>
                <input
                  style={{ color: "black" }}
                  required
                  placeholder={d["title"][language]}
                  type="text"
                  className="form-control settings-form-values"
                  name="title"
                  value={notifyMsg.title}
                  onChange={handleChange}
                />

                <label className="settings-form-titles">
                  {d["message"][language]}
                </label>
                <textarea
                  style={{ color: "black" }}
                  required
                  type="text"
                  placeholder="message"
                  className="form-control settings-form-values h-100"
                  name="message"
                  value={notifyMsg.message}
                  onChange={handleChange}
                />
              </div>
              <div className="text-center pt-3">
                <button type="submit" className="btn add-user w-100">
                  {d["send"][language]}
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
      {/* <div className="patient page-titles pt-0 mb-0">
        <ol className="breadcrumb">
          <li className="breadcrumb-item fs-24">{d["patients"][language]}</li>
          <li className="breadcrumb-item active fs-24">
            {data?.patient?.patient_lastname} {data?.patient?.patient_firstname}
          </li>
        </ol>
      </div> */}
      {/* <div className="row">
        <div className="col-lg-12 p-0">
          <Card className="p-0">
            <div className="card-body px-2 ">
              <div className="container">
                <Row>
                  <Col md="3">
                    <div className="text-center " style={{ marginTop: 0 }}>
                      <img
                        src={(() => {
                          if (data?.user?.profil_picture != null) {
                            return (
                              "https://pharmacy.shls.care/prescription/" +
                              data?.user?.profil_picture
                            );
                          } else {
                            if (
                              data?.user?.patient_sexe === "male" ||
                              data?.user?.patient_sexe === "Male"
                            ) {
                              return patientPhoto;
                            } else {
                              return woman;
                            }
                          }
                        })()}
                        className="img-fluid rounded-circle "
                        alt="profile"
                        style={{ width: "100px" }}
                      />{" "}
                      <br />
                      <Button
                        className="btn btn-sm d-block mt-2 mb-1 rounded font-weight-bold"
                        style={{ margin: "auto" }}
                        variant="warning"
                        onClick={() => setShowModal(true)}
                      >
                        {d["notify"][language]}
                      </Button>
                      <Button
                        className="btn btn-sm my-3 rounded font-weight-bold"
                        style={{ margin: "auto" }}
                        variant="danger"
                        onClick={handleUnfollow}
                      >
                        {d["stop following"][language]}
                      </Button>
                    </div>
                  </Col>
                  <Col md={9}>
                    <h4 className=" mb-3">
                      <span className="text-primary">
                        {data?.user?.patient_firstname}{" "}
                        {data?.user?.patient_lastname}{" "}
                      </span>
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="mb-0">
                          <i
                            style={{ width: "16px" }}
                            className="fa fa-male mr-2"
                          ></i>
                          <span style={{ fontWeight: "bold" }}>
                            {d["sex"][language]}:
                          </span>{" "}
                          {data?.user?.patient_sexe}
                        </p>
                        <p className="mb-1">
                          <i
                            style={{ width: "16px" }}
                            className="fa fa-phone mr-2"
                          ></i>
                          <span style={{ fontWeight: "bold" }}>
                            {d["phone"][language]}:
                          </span>{" "}
                          {data?.user?.patient_phonenumber}
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p className="mb-1">
                          <i
                            style={{ width: "16px" }}
                            className="fa fa-envelope mr-2"
                          ></i>
                          <span style={{ fontWeight: "bold" }}>Email:</span>{" "}
                          {data?.user?.patient_email}
                        </p>
                        <p className="mb-1">
                          <i
                            style={{ width: "16px" }}
                            className="fa fa-map-pin mr-2"
                          ></i>
                          <span style={{ fontWeight: "bold" }}>
                            {d["address"][language]}:
                          </span>{" "}
                          {data?.user?.patient_address} ,{" "}
                          {data?.user?.patient_ville}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <Tab.Container defaultActiveKey={1}>
        <div className="d-md-flex d-block mb-3 pb-3 border-bottom">
          <div className="card-action card-tabs mb-md-0 mb-3  mr-auto">
            <Nav as="ul" className="nav nav-tabs tabs-lg">
              <Nav.Item as="li" key={1}>
                <Nav.Link
                  eventKey={1}
                  onClick={() => {
                    setTab("accepted");
                  }}
                >
                  <span>{accepted?.length}</span>
                  {d["accepted"][language]}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={2}>
                <Nav.Link
                  eventKey={2}
                  onClick={() => {
                    setTab("pending");
                  }}
                >
                  <span>{pending?.length}</span>
                  {d["pending e"][language]}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" key={3}>
                <Nav.Link
                  eventKey={3}
                  onClick={() => {
                    setTab("rejected");
                  }}
                >
                  <span>{rejected?.length}</span>
                  {d["rejected"][language]}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-xxl-12 col-lg-12">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="navpills-1"
                role="tabpanel"
              >
                <Tab.Content>
                  <Tab.Pane eventKey={1} key={1}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Table responsive>
                              <thead className="bg-success-light">
                                <tr>
                                  <th>
                                    <strong>#</strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription number"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription note"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>{d["date"][language]}</strong>
                                  </th>
                                  <th>
                                    <strong>{d["status"][language]}</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {accepted?.map((item, index) => {
                                  const name =
                                    item.prescription_name.split(".");

                                  return (
                                    <tr>
                                      <td>
                                        <strong>{index + 1}</strong>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <span className="w-space-no">
                                            {name[0]}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{item.prescription_note}</td>
                                      <td>{getDate(item.created_at)}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-circle text-success mr-1"></i>{" "}
                                          {item.status}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2} key={2}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Table responsive>
                              <thead className="bg-warning-light">
                                <tr>
                                  <th>
                                    <strong>#</strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription number"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription note"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>{d["date"][language]}</strong>
                                  </th>
                                  <th>
                                    <strong>{d["status"][language]}</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {pending?.map((item, index) => {
                                  const name =
                                    item.prescription_name.split(".");

                                  return (
                                    <tr>
                                      <td>
                                        <strong>{index + 1}</strong>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <span className="w-space-no">
                                            {name[0]}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{item.prescription_note}</td>
                                      <td>{getDate(item.created_at)}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-circle text-warning mr-1"></i>{" "}
                                          {item.status}
                                        </div>
                                      </td>
                                      <td>{getDate(item.updated_at)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={3} key={3}>
                    <Row>
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Table responsive>
                              <thead className="bg-danger-light">
                                <tr>
                                  <th>
                                    <strong>#</strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription number"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>
                                      {d["prescription note"][language]}
                                    </strong>
                                  </th>
                                  <th>
                                    <strong>{d["date"][language]}</strong>
                                  </th>
                                  <th>
                                    <strong>{d["status"][language]}</strong>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {rejected?.map((item, index) => {
                                  const name =
                                    item.prescription_name.split(".");

                                  return (
                                    <tr>
                                      <td>
                                        <strong>{index + 1}</strong>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <span className="w-space-no">
                                            {name[0]}
                                          </span>
                                        </div>
                                      </td>
                                      <td>{item.prescription_note}</td>
                                      <td>{getDate(item.created_at)}</td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <i className="fa fa-circle text-danger mr-1"></i>{" "}
                                          {item.status}
                                        </div>
                                      </td>
                                      <td>{getDate(item.updated_at)}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </div>
        </div>
      </Tab.Container>
      <Modal
        centered
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        className="modal fade"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{d["send notification"][language]}</h5>
            <button
              type="button"
              onClick={() => {
                setShowModal(false);
              }}
              className="close"
              data-dismiss="modal"
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="text-black font-w400 mt-3">
                  {d["title"][language]}
                </label>
                <input
                  style={{ color: "black" }}
                  required
                  placeholder={d["title"][language]}
                  type="text"
                  className="form-control"
                  name="title"
                  value={notifyMsg.title}
                  onChange={handleChange}
                />

                <label className="text-black font-w400 mt-3">
                  {d["message"][language]}
                </label>
                <textarea
                  style={{ color: "black" }}
                  required
                  type="text"
                  placeholder="message"
                  className="form-control"
                  name="message"
                  value={notifyMsg.message}
                  onChange={handleChange}
                />
              </div>
              <div className="text-center mt-4">
                <button type="submit" className="btn btn-primary">
                  {d["send"][language]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal> */}
    </Fragment>
  );
}

export default PatientDetails;
