import { useState, useEffect } from "react";

function useFetch(url, request, initialLoading) {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(initialLoading ?? false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    let controller = new AbortController();
    if (isMounted) setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(
          url,
          {
            ...request,
            signal: controller.signal,
          },
          { mode: "cors" }
        );
        const data = await response.json();
        if (isMounted) setData(data);
      } catch (error) {
        if (isMounted) setError(error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
      isMounted = false;
    };
  }, [url]);

  return { data, loading, error };
}

export default useFetch;
