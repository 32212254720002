import React from "react";
import moment from "moment";
import { useContext } from "react";
import { LanguageContext } from "../../resources/languageContext";
import d from "../../resources/dictionary.json";

const Footer = () => {
  const { language } = useContext(LanguageContext);
  return (
    <div className="footer" style={{ position: "fixed", bottom: "0",width:"100%",height:"5vh", left: "50%", transform: "translateX(-50%)" }}>
      <div className="copyright">
        <p>
          {d["Copyright © Developed by"][language]+" "}
          {/* <a href="http://dexignlab.com/" target="_blank"> */}
          <a href="https://web.adspot.pub/" target="_blank" >
            SPAS Wiser Global Communication
          </a>{" "}
          {moment().format("YYYY")} | <a href="mailto:support@shls.care" target="_blank" >
            support@shls.care
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
