import React, { useState, useEffect, useContext, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import d from "../../resources/dictionary.json";
import { LanguageContext } from "../../resources/languageContext";
import Cookies from "js-cookie";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";

import PendingPrescription from "../components/custom/PendingPrescription";

export default function Dashboard() {
  const token = Cookies.get("jwt");
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState({ patients: [], prescription: [] });
  const [pendingPrescriptions, setPendingPrescriptions] = useState([]);
  const [partiallyAcceptedPrescriptions, setPartiallyAcceptedPrescriptions] =
    useState([]);
  const [acceptedPrescriptions, setAcceptedPrescriptions] = useState([]);
  const [readyPrescriptions, setReadyPrescriptions] = useState([]);
  const [recoveredPrescriptions, setRecoveredPrescriptions] = useState([]);
  const [rejectedPrescriptions, setRejectedPrescriptions] = useState([]);
  const [pendingPatients, setPendingPatients] = useState([]);
  const [acceptedPatients, setAcceptedPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    getData();
  }, []);
  // 217.182.192.87:3000/api
  const getData = async () => {
    const request = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/pharmacy/dashboard",
        request,
        {
          mode: "cors",
        }
      );
      if (response.status === 200) {
        let data = await response.json();
        console.log(data);
        data = data.data;
        setData(data);
        let pendingPres = data.prescription.filter((presc) => {
          return presc.status === "pending";
        });
        setPendingPrescriptions(pendingPres);
        setPartiallyAcceptedPrescriptions(
          data.prescription.filter((presc) => {
            return presc.status === "partially accepted";
          })
        );
        setAcceptedPrescriptions(
          data.prescription.filter((presc) => {
            return presc.status === "accepted";
          })
        );
        setReadyPrescriptions(
          data.prescription.filter((presc) => {
            return presc.status === "ready";
          })
        );
        setRecoveredPrescriptions(
          data.prescription.filter((presc) => {
            return presc.status === "recovered";
          })
        );
        setRejectedPrescriptions(
          data.prescription.filter((presc) => {
            return presc.status === "rejected";
          })
        );
        const pendingPa = data.patients.filter((patient) => {
          return patient.follow_pharmacy_status === "pending";
        });
        setPendingPatients(pendingPa);
        setAcceptedPatients(
          data.patients.filter((patient) => {
            return patient.follow_pharmacy_status === "accepted";
          })
        );
      } else if (response.status === 401) {
        console.log("unauthorized");
        // history.push("/login-page");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center h-75">
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <div
      className="m-4 dashboard-container row"
      style={{ height: "calc(100vh - 11rem)" }}
    >
      <div className="col-xl-8 col-lg-12 col-md-12 h-auto ">
        <div className="row h-auto  mb-4">
          <div className="col left-side-top  h-auto  row w-100 m-0 p-0">
            <div className="col-xl col-lg col-md-12 col-md-12 h-auto p-0 ">
              <Link
                to="/prescriptions"
                style={{ cursor: "pointer", textDecorationLine: "none" }}
              >
                <div className="widget-stat card prescriptions-bg  mb-0 h-100 " style={{opacity:"80%"}}>
                  <div className="card-body ">
                    <div className="d-flex flex-column prescriptions-section">
                      <p className="mb-0 prescription-titles">
                        {d["prescriptions"][language]}
                      </p>
                      <div className="d-flex justify-content-between">
                        <h3 className="text-white">
                          {data.prescription.length}
                        </h3>
                        <span className="">
                          <i
                            className="fa fa-edit text-white icons"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl col-lg col-md-12  col-md-12 h-auto p-0">
              <Link
                to={{
                  pathname: "/patients",
                  state: { tab: "pending", activeTab: 2 },
                }}
                style={{ cursor: "pointer", textDecorationLine: "none" }}
              >
                <div className="widget-stat card pending-users-bg  mb-0 h-100 " style={{opacity:"80%"}}>
                  <div className="card-body ">
                    <div className="d-flex flex-column prescriptions-section">
                      <p className="mb-0 prescription-titles">
                        {d["pending patients"][language]}
                      </p>
                      <div className="d-flex justify-content-between">
                        <h3 className="text-white">{pendingPatients.length}</h3>
                        <span className="">
                          <i
                            className="fa fa-users text-white icons"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-xl col-lg col-md-12 col-md-12 h-auto p-0 ">
              <Link
                to="/patients"
                style={{ cursor: "pointer", textDecorationLine: "none" }}
              >
                <div className="widget-stat card client-bg  mb-0 h-100  " style={{opacity:"80%"}}>
                  <div className="card-body ">
                    <div className="d-flex flex-column prescriptions-section">
                      <p className="mb-0 prescription-titles">
                        {d["total patients"][language]}
                      </p>
                      <div className="d-flex justify-content-between">
                        <h3 className="text-white">
                          {acceptedPatients.length}
                        </h3>
                        <span className="">
                          <i
                            className="fa fa-user-plus text-white icons"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="row  " style={{ height: "calc(100% - 12rem)" }}>
          <div className="col-xl col-lg pt-0 pl-0 pr-0 h-auto">
            <div className="mb-3">
              <span className="chart-title">
                {d["prescriptions"][language]}
              </span>
            </div>

            <div
              className="card-body bg-white"
              style={{ borderRadius: "16px", height: "calc(100% - 44px)" }}
            >
              <ReactApexChart
                type="pie"
                height="100%"
                series={[
                  pendingPrescriptions?.length,
                  partiallyAcceptedPrescriptions?.length,
                  acceptedPrescriptions?.length,
                  readyPrescriptions?.length,
                  recoveredPrescriptions?.length,
                  rejectedPrescriptions?.length,
                ]}
                options={{
                  labels: [
                    d["pending e"][language] +
                      " ( " +
                      pendingPrescriptions.length +
                      " )",
                    d["partially accepted e"][language] +
                      " ( " +
                      partiallyAcceptedPrescriptions.length +
                      " )",
                    d["accepted e"][language] +
                      " ( " +
                      acceptedPrescriptions.length +
                      " )",
                    d["ready e"][language] +
                      " ( " +
                      readyPrescriptions.length +
                      " )",
                    d["recovered e"][language] +
                      " ( " +
                      recoveredPrescriptions.length +
                      " )",
                    d["rejected e"][language] +
                      " ( " +
                      rejectedPrescriptions.length +
                      " )",
                  ],
                  colors: [
                    "#ffc107",
                    "#c6ff00",
                    "#198754",
                    "#4fc3f7",
                    "#ce93d8",
                    "#dc3545",
                  ],
                  chart: {
                    events: {
                      legendClick: function (
                        chartContext,
                        seriesIndex,
                        config
                      ) {
                        console.log(seriesIndex);

                        let active = "pending";
                        if (seriesIndex === 1) {
                          active = "partially accepted";
                        } else if (seriesIndex === 2) {
                          active = "accepted";
                        } else if (seriesIndex === 3) {
                          active = "ready";
                        } else if (seriesIndex === 4) {
                          active = "recovered";
                        } else if (seriesIndex === 5) {
                          active = "rejected";
                        }
                        history.push({
                          pathname: "/prescriptions",
                          state: { tab: active, activeTab: seriesIndex + 1 },
                        });
                      },
                    },
                  },
                  stroke: {
                    width: 5,
                  },
                  responsive: [
                    {
                      breakpoint: 576, // Adjust the breakpoint based on your requirement
                      options: {
                        chart: {
                          width: "100%", // Make the chart width 100% for smaller screens
                        },
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl col-lg col-md-12 h-100 p-0">
        <div
          className="card mb-0 h-100 p-0"
          style={{
            borderRadius: "1rem",
            border: "transparent",
          }}
        >
          <div className="dashboard-list-header">
            <span className="dashboard-list-titles">
              {d["pending prescriptions"][language]}
            </span>
            <span className="fake-plus-icon dashboard-list-length">
              {pendingPrescriptions.length}
            </span>
          </div>
          <PerfectScrollbar
            className="card-body loadmore-content height620 dlab-scroll p-0 "
            id="PendingPrescriptionsContent"
          >
            {pendingPrescriptions.map((item, index) => {
              return <PendingPrescription item={item} key={index} />;
            })}
          </PerfectScrollbar>
        </div>
      </div>
    </div>
  );
}
