// import "./messaging_init_in_sw";

import React, { Fragment, useState, useMemo } from "react";
// Toast container
import { ToastContainer } from "react-toastify";
/// Components
import Markup from "./jsx";

/// Style
import "./css/style.css";
// import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { withResizeDetector } from "react-resize-detector";
import { LanguageContext } from "./resources/languageContext";

const App = ({ width, targetRef }) => {
  const body = document.querySelector("body");

  width >= 1300
    ? body.setAttribute("data-sidebar-style", "full")
    : width <= 1299 && width >= 767
    ? body.setAttribute("data-sidebar-style", "mini")
    : body.setAttribute("data-sidebar-style", "overlay");

  const detectLanguage = () => {
    const storedLang = localStorage.getItem("userLanguage");
    const browserLang = window.navigator.language;
    if (!storedLang) {
      if (browserLang.includes("fr")) {
        return "fr";
      } else {
        return "en";
      }
    } else {
      return storedLang;
    }
  };

  const [language, setLanguage] = useState(detectLanguage());
  const value = useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage]
  );

  return (
    <div ref={targetRef}>
      <LanguageContext.Provider value={value}>
        <Markup className="h-100" />
        <ToastContainer />
      </LanguageContext.Provider>
    </div>
  );
};

export default withResizeDetector(App);
