import React, { useContext, useState } from "react";
import logo from "../../../images/LOGOSH.png";
import d from "../../../resources/dictionary.json";
import { LanguageContext } from "../../../resources/languageContext";

const FPW = () => {
  const { language } = useContext(LanguageContext);
  const [forgotData, setForgotData] = useState({});
  const [mailSent, setMailSent] = useState(false);
  const handleBlur = (e) => {
    const newForgotData = { ...forgotData };
    newForgotData[e.target.name] = e.target.value;
    setForgotData(newForgotData);
  };

  const resetPassword = async () => {
    const request = {
      method: "POST",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        doctor_email: forgotData.email,
      }),
    };
    try {
      let response = await fetch(
        "https://pharmacy.shls.care/api/doctor/reset-password",
        request,
        {
          mode: "cors",
        }
      );

      if (response.status === 200) {
        setMailSent(true);
      }
    } catch (err) {
      // toast.error(err.message);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    resetPassword();
  };

  return (
    <div className="row justify-content-center  align-items-center h-80">
      <div className="col-md-6">
        <div className="authincation-content">
          <div className="row no-gutters">
            <div className="col-xl-12">
              <div className="auth-form">
                <div className="text-center mb-3">
                  <img src={logo} alt="" style={{ height: "200px" }} />
                </div>
                <h4 className="text-center mb-4 text-white">
                  {d["forgot password"][language]}
                </h4>

                {mailSent ? (
                  <h4 className="text-center text-white">
                    {d["mail sent check inbox"][language]}
                  </h4>
                ) : (
                  <form
                    action="./"
                    onSubmit={submitHandler}
                    style={{ height: "150px" }}
                  >
                    <div className="form-group">
                      <label className="mb-1 text-white">
                        {" "}
                        <strong>Email</strong>{" "}
                      </label>
                      <input
                        style={{ color: "black" }}
                        type="email"
                        className="form-control"
                        placeholder="email@email.com"
                        name="email"
                        onChange={handleBlur}
                      />
                    </div>
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="btn bg-white text-primary btn-block"
                        onClick={() => submitHandler}
                      >
                        {d["reset"][language]}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FPW;
